import React from 'react'
import { space, width, fontSize, color } from 'styled-system'
import styled from 'styled-components'

const L = styled.a`
${space}
${width}
${fontSize}
${color}
  text-decoration: none;
  display: inline-block;
  transition: background 0.5s;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
export default ({ to, ...props }) => (
  <L
    color="red"
    {...props}
    href={to}
    target="_blank"
    rel="noopener noreferrer"
  />
)
