const items = [
  {
    link: '/cursuri',
    text: 'Index cursuri',
  },
  {
    link: '/cursuri/apologetica',
    text: 'Apologetică',
  },
  {
    link: '/cursuri/cum-sa-studiem-biblia',
    text: 'Cum să studiem Biblia',
  },

  {
    link: '/cursuri/calatorie-prin-vechiul-testament',
    text: 'Călătorie prin Vechiul Testament',
  },

  {
    link: '/cursuri/casatoria',
    text: 'Căsătoria',
  },

  {
    link: '/cursuri/comunicarea-cuvantului-lui-dumnezeu',
    text: 'Comunicarea Cuvântului lui Dumnezeu',
  },

  {
    link: '/cursuri/cresterea-copiilor-pentru-dumnezeu',
    text: 'Creșterea copiilor pentru Dumnezeu',
  },

  {
    link: '/cursuri/dinamica-bisericii',
    text: 'Dinamica Bisericii',
  },

  {
    link: '/cursuri/dobandirea-unei-inimi-intelepte',
    text: 'Dobândirea unei inimi înțelepte',
  },

  {
    link: '/cursuri/evanghelizare-si-consolidarea-credintei',
    text: 'Evanghelizare și consolidarea credinței',
  },

  {
    link: '/cursuri/focalizarea-liderilor',
    text: 'Focalizarea liderilor',
  },

  {
    link: '/cursuri/galateni-si-romani',
    text: 'Galateni și Romani',
  },

  {
    link: '/cursuri/iata-dumnezeul-tau',
    text: 'Iată Dumnezeul tău',
  },

  {
    link: '/cursuri/misiunea-crestina',
    text: 'Misiunea creștină',
  },

  {
    link: '/cursuri/pastorul-ucenicizator',
    text: 'Păstorul ucenicizator',
  },

  {
    link: '/cursuri/studiu-al-noului-testament',
    text: 'Studiu al Noului Testament',
  },

  {
    link: '/cursuri/teologie-elementară',
    text: 'Teologie elementară',
  },

  {
    link: '/cursuri/ucenicie-in-scoala-lui-isus',
    text: 'Ucenicie în școala lui Isus',
  },

  {
    link: '/cursuri/comentariu-asupra-noului-testament',
    text: 'Comentariu asupra Noului Testament',
  },
  {
    link: '/cursuri/comentariu-asupra-vechiului-testament',
    text: 'Comentariu asupra Vechiului Testament',
  },
]
export default items
