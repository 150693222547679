import React from 'react'
import styled from 'styled-components'
import { space, width, fontSize, color } from 'styled-system'
import { Flex, Box } from 'rebass'

import Link from '../Link'
import ExternalLink from '../ExternalLink'

import footerEntrust from '../images/footer-entrust.png'
import footerPriscila from '../images/footer-priscila.png'
import footerAPME from '../images/footer-apme.png'
import footerOneWayRomania from '../images/footer-one-way-romania.png'
import footerEvanghelic from '../images/footer-evanghelic.png'
import footerSuflet from '../images/footer-suflet-femeie.png'
const Img = styled.img`
  ${space}
`
const Image = ({ to, ...props }) => (
  <ExternalLink to={to}>
    <Img mx={2} {...props} style={{ maxHeight: 70 }} />
  </ExternalLink>
)

const StyledFooter = styled.footer`
  ${space}
  ${width}
  ${fontSize}
  ${color}
  background: #333;
  border-top: 6px solid var(--color-red);
  border-bottom: 1px solid #111;
`
let FooterLink = props => (
  <Link {...props} color="textwhite" bg="none" bgOver="none" />
)

FooterLink = styled(FooterLink)`
  &:not(:last-child) {
    margin-right: 20px;
    position: relative;

    &:after {
      position: absolute;
      right: -10px;
      top: 25%;
      bottom: 25%;
      content: '';
      border-right: 1px solid white;
    }
  }
`

export default props => {
  return (
    <>
      <StyledFooter style={{ ...props.colors }} color="white">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          {...props}
          px={2}
          py={4}
          style={{ margin: '0 auto', ...props.style }}
        >
          <Image to="//entrust4.org" src={footerEntrust} />
          <Image to="//casapriscila.ro" src={footerPriscila} />
          <Image to="//onewayromania.ro" src={footerOneWayRomania} />
          <Image to="//apme.ro" src={footerAPME} />
          <Image to="//evanghelic.ro" src={footerEvanghelic} />
          <Image to="//sufletdefemeie.ro" src={footerSuflet} />
        </Flex>
      </StyledFooter>
      <Box
        color="white"
        py={3}
        fontSize={1}
        style={{
          textAlign: 'center',
          background: '#222',
          borderTop: '1px solid #444',
        }}
      >
        <Box mb={2}>
          <FooterLink to="/">Acasă</FooterLink>
          <FooterLink to="/despre-noi">Despre noi</FooterLink>
          <FooterLink to="/servicii">Servicii</FooterLink>
          <FooterLink to="/sisteme">Sisteme</FooterLink>
          <FooterLink to="/cursuri">Cursuri</FooterLink>
          <FooterLink to="/contact">Contact</FooterLink>
        </Box>
        <Box>
          <Box color="textwhite">© {new Date().getFullYear()} Fundația EBE</Box>
        </Box>
      </Box>
    </>
  )
}
