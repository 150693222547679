export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: [300, 400, 500, 600],
  colors: {
    white: 'white',
    textwhite: '#EEE',
    red: '#F33F3F',
    textred: '#C21E39',
    textgray: '#777',
    darkgray: '#333',
    bordergray: '#E0E0E0',
    fieldbordergray: '#c3c3c3',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
}
