import React from 'react'
import PageWithLeftMenu from './PageWithLeftMenu'
import SEO from './seo'

import items from '../pages/cursuri/_items'
export default ({ children, pageContext }) => {
  const {
    frontmatter: { title },
  } = pageContext

  return (
    <PageWithLeftMenu title={(title || '').toUpperCase()} items={items}>
      <SEO
        title={`Curs - ${title}`}
        keywords={[title, 'curs creștin', `carte creștină`]}
      />
      {children}
    </PageWithLeftMenu>
  )
}
