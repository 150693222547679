import React from 'react'
import { withTheme } from 'styled-components'
import { Link } from 'gatsby'
import { space, width, fontSize, color } from 'styled-system'
import styled from 'styled-components'
const L = styled(Link)`
${space}
${width}
${fontSize}
${color}
  text-decoration: none;
  display: inline-block;
  transition: background 0.5s;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.colors[props.bgOver] || ''};
    color: ${props => props.theme.colors[props.colorOver] || ''};
  }
`
export default withTheme(({ activeColor, activeClassName, to, ...props }) => {
  activeColor = props.theme.colors[activeColor]

  const activeStyle = { ...props.activeStyle }
  if (activeColor) {
    activeStyle.color = activeColor
  }
  let getProps
  if (props.showPartialActive) {
    getProps = props => {
      const { isPartiallyCurrent, isCurrent } = props
      return (isPartiallyCurrent && to != '/') || isCurrent
        ? { className: activeClassName, style: activeStyle }
        : null
    }
  }
  return (
    <L
      color="white"
      bg="red"
      bgOver="darkgray"
      to={to}
      {...props}
      activeStyle={activeStyle}
      getProps={getProps}
    />
  )
})
