import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { Box } from 'rebass'
import theme from './theme'
import Header from './header'
import Footer from './Footer'
import './layout.css'

const colors = Object.keys(theme.colors).reduce((acc, colorName) => {
  acc[`--color-${colorName}`] = theme.colors[colorName]
  return acc
}, {})

const MAX_WIDTH = 960

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <Header
            style={{ ...colors }}
            siteTitle={data.site.siteMetadata.title}
          />
          <div
            style={{
              ...colors,
              margin: `0 auto`,
              maxWidth: MAX_WIDTH,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0,
              flex: 1,
            }}
          >
            <Box px={2} style={{ margin: '0 auto' }}>
              {children}
            </Box>
          </div>
          <Footer colors={colors} style={{ maxWidth: MAX_WIDTH }} />
        </>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
