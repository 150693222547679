import React from 'react'
import styled from 'styled-components'
import { Text, Box, Flex } from 'rebass'
import Layout from './layout'

import Link from '../Link'

import line from '../line'

let NavLink = props => (
  <Link
    color="textred"
    bg="none"
    style={{ maxWidth: '25vw', whiteSpace: 'normal' }}
    activeColor="textgray"
    activeStyle={{ fontWeight: 'bold' }}
    {...props}
  />
)

NavLink = styled(NavLink)`
  &:hover {
    background: none;
    color: var(--color-textgray);
    text-decoration: underline;
  }
`

export default ({ title, items, children }) => {
  return (
    <Layout>
      <Text fontSize={4} mb={3}>
        {title}
      </Text>
      {line}
      <Flex flexDirection={['column', 'row']}>
        <Box
          mx={3}
          pr={3}
          style={{
            alignSelf: 'flex-start',
            borderRight: '1px solid var(--color-bordergray)',
          }}
        >
          <ul>
            {items.map(item => {
              return (
                <li key={item.link}>
                  <NavLink to={item.link}>{item.text}</NavLink>
                </li>
              )
            })}
          </ul>
        </Box>
        <Box color="textgray">{children}</Box>
      </Flex>
    </Layout>
  )
}
