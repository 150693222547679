import PropTypes from 'prop-types'
import React from 'react'

import { Flex, Text } from 'rebass'

import Link from '../Link'

import logo from '../images/logo.png'

const items = [
  {
    text: 'Acasă',
    link: '/',
  },
  {
    text: 'Despre noi',
    link: '/despre-noi',
  },
  {
    text: 'Servicii',
    link: '/servicii',
  },
  { text: 'Sisteme', link: '/sisteme' },
  { text: 'Cursuri', link: '/cursuri' },
  { text: 'Contact', link: '/contact' },
]

const renderItem = ({ text, link }) => {
  return (
    <Link
      key={link}
      to={link}
      bg="none"
      bgOver="none"
      color="textgray"
      colorOver="red"
      activeColor="red"
      mx={[2, 3]}
      showPartialActive
      style={{ whiteSpace: 'nowrap' }}
    >
      {text}
    </Link>
  )
}

const Header = ({ siteTitle, style }) => (
  <div
    style={{
      ...style,
      background: `white`,
      marginBottom: `1.45rem`,
    }}
  >
    <Flex
      py={3}
      pr={3}
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
    >
      <Link to="/" bg="none" tabIndex={-1} bgOver="none" mr={4}>
        <img
          src={logo}
          style={{
            maxHeight: 50,
            minWidth: 100,
            marginTop: 20,
            marginBottom: 0,
          }}
        />
      </Link>
      {items.map(renderItem)}
    </Flex>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
